var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"dblclick":_vm.dblclick}},[_c(`a-${_vm.record.type || 'input'}`,_vm._b({tag:"component",style:(`width:${_vm.record.options.width}`),attrs:{"value":_vm.value,"type":undefined,"autoSize":{
      minRows: _vm.record.options.minRows,
      maxRows: _vm.record.options.maxRows
    }},on:{"change":_vm.change,"pressEnter":_vm.pressEnter,"search":_vm.search,"focus":_vm.focus,"blur":_vm.blur}},'component',_vm.record.options,false)),_c('a-modal',{attrs:{"title":_vm.record.label,"footer":null,"width":800},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-textarea',_vm._b({attrs:{"value":_vm.value,"autoSize":{
          minRows: 20,
          maxRows: 20
        }},on:{"change":_vm.change}},'a-textarea',_vm.record.options,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }