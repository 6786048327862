<template>
  <div @dblclick="dblclick">
    <component
      v-bind="record.options"
      :is="`a-${record.type || 'input'}`"
      :value="value"
      :type="undefined"
      :autoSize="{
        minRows: record.options.minRows,
        maxRows: record.options.maxRows
      }"
      :style="`width:${record.options.width}`"
      @change="change"
      @pressEnter="pressEnter"
      @search="search"
      @focus="focus"
      @blur="blur"
    />
    <a-modal
      v-model="visible"
      :title="record.label"
      :footer="null"
      :width="800"
    >
        <a-textarea
          v-bind="record.options"
          :value="value"
          :autoSize="{
            minRows: 20,
            maxRows: 20
          }"
          @change="change"
        />
    </a-modal>
  </div>
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxInput',
  mixins: [formEleMixin],
  data () {
    return {
      visible: false
    }
  },
  methods: {
    /**
     * 输入框内容变化时的回调
     * @param e
     */
    change (e) {
      this.$emit('change', e.target.value, this.record.model)
    },
    /**
     * 按下回车的回调
     * @param e
     */
    pressEnter (e) {
      this.$emit('pressEnter', e.target.value, this.record.model)
    },
    /**
     * 点击搜索或按下回车键时的回调
     * @param value
     */
    search (value) {
      this.$emit('search', value, this.record.model)
    },
    /**
     * 双击弹窗显示文本域
     */
    dblclick () {
      if (this.record.type === 'textarea') this.visible = true
    },
    /**
     * 获取当前焦点元素
     */
    focus (e) {
      this.$emit('focus', e.target.value, this.record.model)
    },
    /**
     * 获取当前失去焦点元素
     */
    blur (e) {
      this.$emit('focus', e.target.value, this.record.model)
    }
  }
}
</script>
